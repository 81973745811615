import { useEffect, useState } from 'react';

interface ScrollDetails {
  x: number;
  y: number;
}

export const useWindowScroll = () => {
  // Store the last-known scroll position (when this is initialized, it is set to 0).
  const [scroll, setScroll] = useState<scrolldetails>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const calculateWindowScroll = () => {
      setScroll({
        x: window.scrollX,
        y: window.scrollY,
      });
    };
    calculateWindowScroll();

    // Listen to window scroll events to decide when to re-evaluate the scroll position.
    window.addEventListener('scroll', calculateWindowScroll);

    return () => window.removeEventListener('scroll', calculateWindowScroll);
  }, []);

  return scroll;
};
</scrolldetails>