import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import classNames from 'classnames';
import { TopbarContainer } from '../..';
import { useShopConfig } from '../../../hooks/shopConfig';
import {
  BuilderSection,
  Footer,
  HeroActions,
  IconTreetLogo,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Logo,
  SectionFooterActionV2,
  SectionHeroV2,
} from '../../../components';
import { ABOUT_PAGES_CONTENT_WIDTH, AboutPageTab } from '../aboutPageUtils';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { LANDING_PAGE_SECTION } from '../../../shopConfig/config';
import AboutNavbar from './AboutNavbar';
import { FooterLogo } from '../../../types/shopConfig/shopConfigV2';
import css from '../AboutPage.module.css';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import TopbarTransparencyContext from '../../../context/TopbarTransparencyContext';
import { useWindowScroll } from '../../../hooks/useWindowScroll';
import { BuilderSections } from '../../../util/builder';

const TRANSPARENT_TOPBAR_SCROLL_THRESHOLD = 50; // in pixels

export const AboutPagesFooterAction: FC = () => {
  const {
    landingPageLayoutOrder,
    shopName,
    css: shopConfigV2Css,
    copy,
    footerLogos,
  } = useShopConfig();
  const isMobile = useIsMobile();
  const { allowMarketplace } = useEnabledCustomerExperiences();

  if (!landingPageLayoutOrder.includes(LANDING_PAGE_SECTION.FOOTER)) return null;

  const invertedLogoStyle = shopConfigV2Css?.invertedLogoStyle;
  const footerSubtitle = copy?.footerSubtitle;

  const shopNameEl = invertedLogoStyle ? (
    <logo className="{css.icon}" format="desktop" type="original" style="{invertedLogoStyle}"></logo>
  ) : (
    <typography variant="h1" style="{{" color:="" 'white',="" position:="" 'relative',="" fontWeight:="" 'bold'="" }}="">
      {shopName}
    </typography>
  );

  const footerLogoTypeToEl = {
    [FooterLogo.Brand]: shopNameEl,
    [FooterLogo.Treet]: <icontreetlogo color="white" className="{css.icon}"></icontreetlogo>,
    [FooterLogo.SuperCircle]: (
      <img className="{css.icon}" src="/static/logos/supercircle.png" alt="supercircle-logo">
    ),
  };

  const footerHeaderEl = (
    <box display="flex" alignItems="center" flexDirection="{isMobile" ?="" 'column'="" :="" 'row'}="">
      {footerLogos.map((logoType: FooterLogo, index: number) => (
        <>
          {index !== 0 && (
            <pre className="{classNames({" [css.footerAdditionMobile]:="" isMobile="" })}="">
              <typography variant="h1" style="{{" color:="" 'white',="" position:="" 'relative',="" fontWeight:="" 'bold',="" lineHeight:="" 'unset',="" }}="">
                {`   +   `}
              </typography>
            </pre>
          )}
          {footerLogoTypeToEl[logoType]}
        </>
      ))}
    </box>
  );

  const defaultFooterSubtitle = allowMarketplace
    ? `Buy and sell ${shopName} from your peers. Treet is our partner to help manage this amazing peer-to-peer secondhand experience.`
    : 'Treet is our partner to help manage this amazing resale experience.';
  return (
    <sectionfooteractionv2 headerEl="{footerHeaderEl}" description="{footerSubtitle" ||="" defaultFooterSubtitle}="" actionEl="{<HeroActions"></sectionfooteractionv2>}
    />
  );
};

const SectionHero: FC = () => {
  const { images, builderConfig } = useShopConfig();
  const isMobile = useIsMobile();

  const aboutImageDefaultUrl = images?.aboutImage?.url;
  const aboutImageMobileUrl = images?.aboutImageMobile?.url;
  const aboutImageUrl = (isMobile && aboutImageMobileUrl) || aboutImageDefaultUrl;
  const aboutPageHeroSectionId = builderConfig?.sections?.[BuilderSections.AboutPageHeroContent];

  return (
    <>
      {!aboutPageHeroSectionId && (
        <sectionherov2 imageUrl="{aboutImageUrl}" actionEl="{<HeroActions"></sectionherov2>} />
      )}
      {aboutPageHeroSectionId && (
        <buildersection sectionType="{BuilderSections.AboutPageHeroContent}" sectionId="{aboutPageHeroSectionId}"></buildersection>
      )}
    </>
  );
};

interface NavbarProps {
  selectedTabName: string;
  onTabSelected: (tab: AboutPageTab) => void;
}

const Navbar: FC<navbarprops> = (props) => {
  const { selectedTabName, onTabSelected } = props;

  const { builderConfig } = useShopConfig();

  const aboutPageNavbarSectionId =
    builderConfig?.sections?.[BuilderSections.AboutPageNavbarContent];

  return (
    <>
      {!aboutPageNavbarSectionId && (
        <aboutnavbar onTabSelected="{onTabSelected}" selectedTabName="{selectedTabName}"></aboutnavbar>
      )}
      {aboutPageNavbarSectionId && (
        <buildersection sectionType="{BuilderSections.AboutPageNavbarContent}" sectionId="{aboutPageNavbarSectionId}" sectionData="{{" selectedTabName="" }}="" sectionContext="{{" onTabSelected=""></buildersection>
      )}
    </>
  );
};

interface AboutPagesWrapperProps {
  selectedTabName: string;
  onTabSelected: (tab: AboutPageTab) => void;
  allowFullBleed?: boolean;
}

const AboutPagesWrapper: FC<aboutpageswrapperprops> = (props) => {
  const { selectedTabName, children, allowFullBleed = false, onTabSelected } = props;

  const { css: brandCss } = useShopConfig();
  const scroll = useWindowScroll();

  const isFullBleedTopbar = brandCss?.bleedTopbarIntoHeroImage ?? false;
  const isTopbarTransparent = isFullBleedTopbar && scroll.y < TRANSPARENT_TOPBAR_SCROLL_THRESHOLD;
  const topbarClasses = classNames(css.topbar, {
    [css.topbarAboveHero]: isFullBleedTopbar,
  });

  return (
    <layoutsinglecolumn>
      <layoutwrappertopbar className="{topbarClasses}">
        <topbartransparencycontext.provider value="{isTopbarTransparent}">
          <topbarcontainer></topbarcontainer>
        </topbartransparencycontext.provider>
      </layoutwrappertopbar>
      <layoutwrappermain>
        <sectionhero></sectionhero>
        <navbar onTabSelected="{onTabSelected}" selectedTabName="{selectedTabName}"></navbar>
        <box 8="" 10="" display="flex" flexDirection="column" alignContent="flex-start" alignItems="center" justifyContent="center" bgcolor="{brandCss?.backgroundColor}" Need="" this="" so="" that="" box="" rerenders="" when="" the="" tab="" changes="" and="" padding="" can="" be="" rerendered="" key="{selectedTabName}" {...(!allowFullBleed="" &&="" {="" px:="" xs:="" 3,="" sm:="" 5,="" md:="" },="" py:="" }="" })}="">
          <box width="100%" display="flex" flexDirection="column" {...(!allowFullBleed="" &&="" {="" maxWidth:="" ABOUT_PAGES_CONTENT_WIDTH="" })}="">
            {children}
          </box>
        </box>
        <aboutpagesfooteraction></aboutpagesfooteraction>
      </layoutwrappermain>
      <layoutwrapperfooter>
        <footer></footer>
      </layoutwrapperfooter>
    </layoutsinglecolumn>
  );
};
export default AboutPagesWrapper;
</aboutpageswrapperprops></navbarprops>