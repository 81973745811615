import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Button } from '../../../components';
import { useStyleType } from '../../../hooks/useStyleType';
import * as zendesk from '../../../util/zendesk';
import Content from './Content';
import GeneralInfoTitle from './GeneralInfoTitle';

import css from '../AboutPage.module.css';

const SupportTab: FC = () => {
  const { isCondensedStyle } = useStyleType();

  return (
    <>
      <generalinfotitle>We’re Here To Help</generalinfotitle>
      <content>
        <typography variant="body1">
          Our team will respond to all support messages within 48 hours.
        </typography>
      </content>

      <generalinfotitle>Chat With Us</generalinfotitle>
      <content>
        <typography variant="body1">
          You can chat with support anytime through the bubble in the bottom right corner on most
          pages in Treet. You can also press the button below.
        </typography>
        <box display="{{" md:="" 'flex'="" }}="" mt="{4}">
          <div>
            <button style="{{" display:="" 'block'="" }}="" onClick="{()" ==""> zendesk.open()}
              className={classNames({ [css.mobileButtonCondensed]: isCondensedStyle })}
            >
              Chat With Support
            </button>
          </div>
        </box>
      </content>

      <generalinfotitle>Email</generalinfotitle>
      <content>
        <typography variant="body1">
          Email Treet support at{' '}
          <a href="mailto:support@treet.co">
            <typography variant="body1" className="{css.underlineText}" style="{{" display:="" 'inline'="" }}="">
              support@treet.co
            </typography>
          </a>
          .
        </typography>
      </content>
    </>
  );
};
export default SupportTab;
