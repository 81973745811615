/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import {
  IconBuyerProtectionFilled,
  IconCashFilled,
  IconTreeFilled,
  InformationModule,
} from '../../../components';
import { useShopConfig } from '../../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { formatPayoutPercentage } from '../../../util/currency';
import Content from './Content';
import GeneralInfoTitle from './GeneralInfoTitle';

enum Responsibility {
  Shipping = 'SHIPPING',
  ShippingGeneric = 'SHIPPING_GENERIC',
  Authentication = 'AUTHENTICATION',
  Payments = 'PAYMENTS',
  Disbursements = 'DISBURSEMENTS',
  DisbursementsCredit = 'DISBURSEMENTS_CREDIT',
  Returns = 'RETURNS',
  SiteFunctionality = 'SITE_FUNCTIONALITY',
}

const RESPONSIBILITIES_COPY = {
  [Responsibility.Shipping]: {
    title: 'Shipping Logistics',
    subtitle: 'Facilitating and tracking shipments between Buyers and Sellers.',
  },
  [Responsibility.ShippingGeneric]: {
    title: 'Shipping Logistics',
    subtitle: 'Facilitating and tracking shipments.',
  },
  [Responsibility.Authentication]: {
    title: 'Post Review & Authentication',
    subtitle: 'Reviewing all posts submitted to the site.',
  },
  [Responsibility.Payments]: {
    title: 'Payments',
    subtitle: 'Handling all payments made through the site.',
  },
  [Responsibility.Disbursements]: {
    title: 'Disbursements',
    subtitle: 'Disbursing funds as cash or credit and handling refunds/returns.',
  },
  [Responsibility.DisbursementsCredit]: {
    title: 'Disbursements',
    subtitle: 'Disbursing funds as credit and handling returns.',
  },
  [Responsibility.Returns]: {
    title: 'Returns',
    subtitle: 'Handling all refunds and returns.',
  },
  [Responsibility.SiteFunctionality]: {
    title: 'General Site Functionality',
    subtitle: 'The overall flow and functionality of the site.',
  },
};

const TradeInInformationModule: FC = () => {
  const { creditPayoutPercentage, shopName, treetShopName } = useShopConfig();

  const content = [
    {
      image: <iconbuyerprotectionfilled></iconbuyerprotectionfilled>,
      title: 'A Trusted Platform',
      description:
        'Every time you sell on Treet, you can trust that your items will be taken care of.',
    },
    {
      image: <iconcashfilled></iconcashfilled>,
      title: 'Get Bigger Payouts',
      description: `When you sell on ${treetShopName}, you get ${formatPayoutPercentage(
        creditPayoutPercentage
      )}% of your item’s sale price in credit back to ${shopName}.`,
    },
    {
      image: <icontreefilled></icontreefilled>,
      title: 'Good For The Planet',
      description: 'Participating in resale reduces your environmental impact.',
    },
  ];
  return <informationmodule content="{content}"></informationmodule>;
};

const getPayoutHelperText = (
  cashPayoutPercentage: number,
  creditPayoutPercentage: number,
  shopName: string
) => {
  if (cashPayoutPercentage && creditPayoutPercentage) {
    return `you get ${formatPayoutPercentage(
      creditPayoutPercentage
    )}% of your item’s sale price in credit back to ${shopName} or ${formatPayoutPercentage(
      cashPayoutPercentage
    )}% in cash.`;
  }
  if (creditPayoutPercentage) {
    return `you get ${formatPayoutPercentage(
      creditPayoutPercentage
    )}% of your item’s sale price in credit back to ${shopName}`;
  }
  if (cashPayoutPercentage) {
    return `you earn ${formatPayoutPercentage(cashPayoutPercentage)}% in cash.`;
  }
  return null;
};

const AboutInformationModule: FC = () => {
  const { cashPayoutPercentage, creditPayoutPercentage, shopName, treetShopName } = useShopConfig();
  const { allowSell } = useEnabledCustomerExperiences();

  const content = [
    {
      image: <iconbuyerprotectionfilled></iconbuyerprotectionfilled>,
      title: 'You Verify Every Purchase',
      description: 'Every time you buy with Treet, you verify that the item is as described.',
    },
    ...(allowSell
      ? [
          {
            image: <iconcashfilled></iconcashfilled>,
            title: 'Get Bigger Payouts',
            description: `When you sell on ${treetShopName}, ${getPayoutHelperText(
              cashPayoutPercentage,
              creditPayoutPercentage,
              shopName
            )}`,
          },
        ]
      : []),
    {
      image: <icontreefilled></icontreefilled>,
      title: 'Good For The Planet',
      description: 'Participating in resale reduces your environmental impact.',
    },
  ];
  return <informationmodule content="{content}"></informationmodule>;
};

const Responsibilities: FC = () => {
  const { isBrandDirectOnly, isTradeInOnly, isBuyAndTradeInOnly } = useEnabledCustomerExperiences();
  const { cashPayoutPercentage, creditPayoutPercentage } = useShopConfig();
  const isOnlyCredit = cashPayoutPercentage === 0 && creditPayoutPercentage > 0;

  let content;
  if (isTradeInOnly) {
    content = [
      RESPONSIBILITIES_COPY[Responsibility.ShippingGeneric],
      RESPONSIBILITIES_COPY[Responsibility.DisbursementsCredit],
      RESPONSIBILITIES_COPY[Responsibility.SiteFunctionality],
    ];
  } else if (isBrandDirectOnly || isBuyAndTradeInOnly) {
    content = [
      RESPONSIBILITIES_COPY[Responsibility.Authentication],
      RESPONSIBILITIES_COPY[Responsibility.ShippingGeneric],
      RESPONSIBILITIES_COPY[Responsibility.Payments],
      RESPONSIBILITIES_COPY[Responsibility.Returns],
      RESPONSIBILITIES_COPY[Responsibility.SiteFunctionality],
    ];
  } else if (isOnlyCredit) {
    content = [
      RESPONSIBILITIES_COPY[Responsibility.Authentication],
      RESPONSIBILITIES_COPY[Responsibility.Shipping],
      RESPONSIBILITIES_COPY[Responsibility.Payments],
      RESPONSIBILITIES_COPY[Responsibility.DisbursementsCredit],
      RESPONSIBILITIES_COPY[Responsibility.SiteFunctionality],
    ];
  } else {
    content = [
      RESPONSIBILITIES_COPY[Responsibility.Authentication],
      RESPONSIBILITIES_COPY[Responsibility.Shipping],
      RESPONSIBILITIES_COPY[Responsibility.Payments],
      RESPONSIBILITIES_COPY[Responsibility.Disbursements],
      RESPONSIBILITIES_COPY[Responsibility.SiteFunctionality],
    ];
  }

  return (
    <>
      {content.map((contentSection) => (
        <box key="{contentSection.title}" mb="{2}">
          <typography variant="body1" style="{{" fontWeight:="" 'bold'="" }}="">
            {contentSection.title}
          </typography>
          <typography variant="body1">{contentSection.subtitle}</typography>
        </box>
      ))}
    </>
  );
};

const AboutTreetTab: FC = () => {
  const { shopName } = useShopConfig();
  const { isBrandDirectOnly, isTradeInOnly, isBuyAndTradeInOnly } = useEnabledCustomerExperiences();

  return (
    <>
      <generalinfotitle>About Treet</generalinfotitle>
      <content>
        <box mb="{3}">
          <typography variant="body1">
            {isTradeInOnly
              ? `Trade in your ${shopName} items on a platform managed by Treet.`
              : isBrandDirectOnly || isBuyAndTradeInOnly
              ? `Buy gently used ${shopName} items in a marketplace managed by Treet.`
              : `Buy and sell from other ${shopName} customers in a marketplace managed by Treet.`}
          </typography>
        </box>
        {isTradeInOnly ? <tradeininformationmodule></tradeininformationmodule> : <aboutinformationmodule></aboutinformationmodule>}
      </content>

      <generalinfotitle>What’s Treet Responsible For?</generalinfotitle>
      <content>
        <responsibilities></responsibilities>
      </content>
    </>
  );
};
export default AboutTreetTab;
