/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { InformationModule, InlineTextButton } from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import { useShopConfig } from '../../../hooks/shopConfig';
import {
  getBuyerContentConfig,
  getSellerContentConfig,
  getTradeInContentConfig,
} from '../aboutPageUtils';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { useIsMobile } from '../../../hooks/useIsMobile';
import css from '../AboutPage.module.css';

enum Tabs {
  Buyers = 'BUYERS',
  Sellers = 'SELLERS',
  TradeIn = 'TRADE_IN',
}

const HowItWorksV2: FC = () => {
  const {
    buyerSellerInfoOverrides,
    cashPayoutPercentage,
    creditPayoutPercentage,
    shopName,
    images,
  } = useShopConfig();
  const customIcons = images?.howItWorksCustomImages;
  const { isBrandDirectOnly, allowBuy, allowSell, allowTradeIn, isTradeInOnly } =
    useEnabledCustomerExperiences();
  const isMobile = useIsMobile();

  const [tabSelected, setTabSelected] = useState(Tabs.Buyers);

  const buyerContent = getBuyerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  const sellerContent = getSellerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  const tradeInContent = getTradeInContentConfig(
    shopName,
    customIcons,
    buyerSellerInfoOverrides,
    !isTradeInOnly
  );

  const content =
    tabSelected === Tabs.TradeIn
      ? tradeInContent
      : tabSelected === Tabs.Buyers
      ? buyerContent
      : sellerContent;

  const tabs = (
    <box display="flex" alignItems="center">
      {allowBuy && (
        <inlinetextbutton onClick="{()" ==""> setTabSelected(Tabs.Buyers)} className={css.howItWorksTab}>
          <box mr="{5}">
            <typography variant="h1" className="{classNames({" [css.howItWorksTabSelected]:="" tabSelected="==" Tabs.Buyers="" })}="">
              {isMobile ? 'Buy' : 'For Buyers'}
            </typography>
          </box>
        </inlinetextbutton>
      )}
      {allowSell && (
        <inlinetextbutton onClick="{()" ==""> setTabSelected(Tabs.Sellers)}
          className={css.howItWorksTab}
        >
          <typography variant="h1" className="{classNames({" [css.howItWorksTabSelected]:="" tabSelected="==" Tabs.Sellers="" })}="">
            {isMobile ? 'Sell' : 'For Sellers'}
          </typography>
        </inlinetextbutton>
      )}
      {allowTradeIn && (
        <inlinetextbutton onClick="{()" ==""> setTabSelected(Tabs.TradeIn)}
          className={css.howItWorksTab}
        >
          <box ml="{5}">
            <typography variant="h1" className="{classNames({" [css.howItWorksTabSelected]:="" tabSelected="==" Tabs.TradeIn="" })}="">
              {isMobile ? 'Trade In' : 'For Trade-Ins'}
            </typography>
          </box>
        </inlinetextbutton>
      )}
    </box>
  );

  return (
    <box display="flex" flexDirection="column" alignItems="center" px="{2}" height="{{" xs:="" '450px',="" md:="" '300px'="" }}="">
      {!isBrandDirectOnly && <box mb="{5}">{tabs}</box>}
      <informationmodule content="{content}" direction="{ContentDirection.Horizontal}"></informationmodule>
    </box>
  );
};

export default HowItWorksV2;
