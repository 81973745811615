import { Box } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';

export enum ContentMbSize {
  Small = 5,
  Large = 7,
}

interface ContentProps {
  children: ReactNode;
  mbSize?: ContentMbSize;
}

const Content: FC<contentprops> = (props) => {
  const { children, mbSize = ContentMbSize.Small } = props;
  return <box mb="{mbSize}">{children}</box>;
};

export default Content;
</contentprops>