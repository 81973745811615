import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, NamedLink, TradeInFAQ } from '../../../components';
import { useStyleType } from '../../../hooks/useStyleType';
import { fetchSoldListings } from '../AboutPage.duck';
import TradeInHowItWorks from './TradeInHowItWorks';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';
import Content, { ContentMbSize } from './Content';
import css from '../AboutPage.module.css';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';

const TradeInInformationModule: FC = () => (
  <content mbSize="{ContentMbSize.Large}">
    <tradeinhowitworks></tradeinhowitworks>
  </content>
);

const TradeInFAQs: FC = () => {
  const { isTradeInOnly } = useEnabledCustomerExperiences();

  return (
    <>
      <buyersellerinfotitle>{isTradeInOnly ? 'Notes' : 'Trade-In FAQ’s'}</buyersellerinfotitle>
      <content mbSize="{ContentMbSize.Large}">
        <tradeinfaq></tradeinfaq>
      </content>
    </>
  );
};

const TradeInInfoTab: FC = () => {
  const { isTradeInOnly, allowTradeInAndMarketplace } = useEnabledCustomerExperiences();
  const { isCondensedStyle } = useStyleType();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSoldListings());
  }, []);

  return (
    <>
      <tradeininformationmodule></tradeininformationmodule>
      <tradeinfaqs></tradeinfaqs>
      <box display="{{" md:="" 'flex'="" }}="" justifyContent="center" mb="{5}">
        <namedlink name="{allowTradeInAndMarketplace" ?="" 'NewListingPage'="" :="" 'ManageTradeInsPage'}="" style="{{" textDecoration:="" 'none',="" display:="" 'flex',="" justifyContent:="" 'center'="" }}="">
          <button className="{classNames({" [css.mobileButtonCondensed]:="" isCondensedStyle="" })}="">
            {isTradeInOnly ? 'Start Selling' : 'Trade In'}
          </button>
        </namedlink>
      </box>
    </>
  );
};

export default TradeInInfoTab;
