import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { BuilderSection, HeroActions, SectionAbout } from '../../../components';
import { useShopConfig, useShopConfigV2 } from '../../../hooks/shopConfig';
import { AboutPageInfoTabSection } from '../../../types/shopConfig/shopConfigV2';
import { ABOUT_PAGES_CONTENT_WIDTH } from '../aboutPageUtils';
import HowItWorksV2 from './HowItWorksV2';
import TradeInHowItWorks from './TradeInHowItWorks';
import { BuilderSections } from '../../../util/builder';
import { BuilderAboutPageInfoTabResponseData } from '../../../types/builder/builder';

const AboutSection: FC = () => {
  const { css: brandCss } = useShopConfigV2();

  return (
    <box 6="" display="flex" justifyContent="center" bgcolor="{brandCss?.tintColor}" width="100%" px="{{" xs:="" 3,="" md:="" 5,="" lg:="" }}="" py="{5}">
      <sectionabout actionEl="{<HeroActions" isOnImage="{false}"></sectionabout>} />
    </box>
  );
};

const HowItWorksSection: FC = () => (
  <box my="{5}" width="100%" maxWidth="{ABOUT_PAGES_CONTENT_WIDTH}" alignSelf="center">
    <howitworksv2></howitworksv2>
  </box>
);

const InfoTab: FC = () => {
  const { css: brandCss } = useShopConfigV2();
  const { builderConfig } = useShopConfig();

  const [builderData, setBuilderData] = useState<builderaboutpageinfotabresponsedata |="" undefined="">();
  const aboutPageInfoTabSectionId =
    builderConfig?.sections?.[BuilderSections.AboutPageInfoTabContent];
  const CustomAboutSection = (
    <buildersection sectionType="{BuilderSections.AboutPageInfoTabContent}" sectionId="{aboutPageInfoTabSectionId}" fetchDataFromBuilder="{(builderResponse)" ==""> setBuilderData(builderResponse)}
    />
  );
  const shouldShowHowItWorksModule =
    !aboutPageInfoTabSectionId || builderData?.shouldShowHowItWorksModule;

  const aboutPageInfoTabLayout = brandCss?.aboutPageInfoTabLayout || [];

  const sectionToComponent = {
    [AboutPageInfoTabSection.About]: aboutPageInfoTabSectionId ? (
      CustomAboutSection
    ) : (
      <aboutsection key="about"></aboutsection>
    ),
    [AboutPageInfoTabSection.HowItWorks]: shouldShowHowItWorksModule ? (
      <howitworkssection key="howItWorks"></howitworkssection>
    ) : undefined,
    [AboutPageInfoTabSection.TradeInHowItWorks]: shouldShowHowItWorksModule ? (
      <box display="flex" justifyContent="center" px="{3}" py="{7}" key="tradeInHowItWorks">
        <tradeinhowitworks></tradeinhowitworks>
      </box>
    ) : undefined,
  };

  return <>{aboutPageInfoTabLayout.map((section) => sectionToComponent[section])}</>;
};

export default InfoTab;
</buildersection></builderaboutpageinfotabresponsedata>