import { Box, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { useShopConfig } from '../../../hooks/shopConfig';
import { FontConfigSection } from '../../../types/shopConfig/shopConfigV2';

interface GeneralInfoTitleProps {
  children: ReactNode;
}

const GeneralInfoTitle: FC<generalinfotitleprops> = (props) => {
  const { children } = props;

  const { fontConfig } = useShopConfig();
  const typographyVariant = fontConfig[FontConfigSection.AboutPageGeneralInfoTitle];

  return (
    <box mb="{1}">
      <typography variant="{typographyVariant}">{children}</typography>
    </box>
  );
};

export default GeneralInfoTitle;
</generalinfotitleprops>