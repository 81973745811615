import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  BuyerFAQ,
  InformationModule,
  NamedLink,
  RecentArrivalsCarousel,
} from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import { MIN_CAROUSEL_LISTINGS } from '../../../components/ListingItemsCarousel/ListingItemsCarousel';
import { useShopConfigV2 } from '../../../hooks/shopConfig';
import { useIsShopSideLaunched } from '../../../hooks/useIsShopSideLaunched';
import { getBuyerContentConfig } from '../aboutPageUtils';
import Content, { ContentMbSize } from './Content';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';
import {
  fetchRecentListings,
  RecentArrivalsCarouselState,
} from '../../../ducks/recentArrivalsCarousel.duck';

const BuyerInformationModule: FC = () => {
  const { buyerSellerInfoOverrides, cashPayoutPercentage, creditPayoutPercentage, images } =
    useShopConfigV2();
  const customIcons = images?.howItWorksCustomImages;

  const content = getBuyerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );
  return (
    <>
      <buyersellerinfotitle>Buying on Treet</buyersellerinfotitle>
      <content mbSize="{ContentMbSize.Large}">
        <informationmodule content="{content}" direction="{ContentDirection.Horizontal}"></informationmodule>
      </content>
    </>
  );
};

const BuyerFAQs: FC = () => (
  <>
    <buyersellerinfotitle>Buyer FAQ’s</buyersellerinfotitle>
    <content mbSize="{ContentMbSize.Large}">
      <buyerfaq></buyerfaq>
    </content>
  </>
);

const RecentlyListedListings: FC = () => {
  const { recentListings } = useSelector<any>(
    (state) => state.recentArrivalsCarousel
  ) as RecentArrivalsCarouselState;
  const isShopSideLaunched = useIsShopSideLaunched();

  if (!isShopSideLaunched || recentListings.length < MIN_CAROUSEL_LISTINGS) return null;

  return (
    // If styles for BuyerSellerInfoTitle are updated, also update the title style in
    // RecentArrivalsCarousel
    <content mbSize="{ContentMbSize.Large}">
      <recentarrivalscarousel></recentarrivalscarousel>
    </content>
  );
};

const BuyerInfoTab: FC = () => {
  const dispatch = useDispatch();
  const isShopSideLaunched = useIsShopSideLaunched();

  useEffect(() => {
    dispatch(fetchRecentListings());
  }, []);

  return (
    <>
      <buyerinformationmodule></buyerinformationmodule>
      <buyerfaqs></buyerfaqs>
      <recentlylistedlistings></recentlylistedlistings>
      {isShopSideLaunched && (
        <box display="{{" md:="" 'flex'="" }}="" justifyContent="center" mb="{5}">
          <namedlink name="LandingPage" to="{{" search:="" ''="" }}="" style="{{" textDecoration:="" 'none'="">
            <button>Shop All</button>
          </namedlink>
        </box>
      )}
    </>
  );
};
export default BuyerInfoTab;
</any>