import { Box, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';

interface BuyerSellerInfoTitleProps {
  children: ReactNode;
}

const BuyerSellerInfoTitle: FC<buyersellerinfotitleprops> = (props) => {
  const { children } = props;

  return (
    <box mb="{3}">
      <typography variant="h1" style="{{" textAlign:="" 'center'="" }}="">
        {children}
      </typography>
    </box>
  );
};

export default BuyerSellerInfoTitle;
</buyersellerinfotitleprops>